import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { UserInfoContext } from "./UserInfoprovider";
export default function Login(){
    const [password,setPassword]= useState("");
    const {userInfo,setUserInfo} = useContext(UserInfoContext);
    const [username,setUsername] = useState("");
    const [redirect,setRedirect]= useState(false);
    const [Error,setError] = useState(false);

    function loginPost(e){
        e.preventDefault();
            setError(false);
        if(username===undefined||username.length<8){
            setError(true);
            return;
        }
        if(password===undefined||password.length<8){
            setError(true);
            return;
        }
        setError(false);
        fetch('https://api.wenqing.world/login', {
            method: 'POST', // 请求方法
            headers: {
            'Content-Type': 'application/json' // 请求头，指定发送 JSON 格式数据
            },
            credentials:'include',
            body: JSON.stringify({username,password}) // 将数据对象转换为 JSON 字符串
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            response.json().then(data=>{
                setUserInfo(data);
            })
            setRedirect(true);
        })
        .then(data => {
            console.log('Success:', data); // 处理成功的响应数据
        })
        .catch(error => {
            setError(true);
        });
    };

    if(userInfo&&userInfo.username.length) return(
        <Navigate to="/"/>
    )
    if(redirect===true)return (<Navigate to={'/'}/>);
    return(
        <>
        <form className="login" onSubmit={loginPost}>

            <h1>Login</h1>

            <input type="text" placeholder="username" value = {username} onChange={e=>{
                setUsername(e.target.value);
            }}></input>
            <input type="password" placeholder="password" value={password} onChange={e=>{
                setPassword(e.target.value);
            }}>

            </input>
            {Error?(
                <div className="Error">
                <p>unexpected Error! </p>
                </div>
            ):null}
            <button className="submit">login</button>
        </form>
        </>
    )
}