import './App.css';

import {Routes,Route} from 'react-router-dom';
import Layout from './Layout';
import Entries from './components/Entries';
import Login from './components/Login';
import Register from './components/Register';
import {UserInfoprovider} from './components/UserInfoprovider';
import Create from './components/Create';
import Post from './components/Post';
import Edit from './components/Edit';
import { LoadingInfoprovider} from './components/LoadingInfoprovider';
function App() {
  return (
    <LoadingInfoprovider>
    <UserInfoprovider>
      <Routes>
        <Route path={"/"} element={<Layout/>}>
        <Route index element={<Entries/>}/>
        <Route path={'WelkinLogin'} element={
          <Login/>
        }/>
        <Route path={'CreateWelkin'} element={
          <Create/>
        }/>
        <Route path={'Edit'} element={
          <Edit/>
        }/>
        <Route path={'post/:id'} element={
        <Post/>}/>

        <Route path={'*'} element={
        <Entries/>}/>

        </Route>
      </Routes>
    </UserInfoprovider>
</LoadingInfoprovider>
  );
}

export default App;
