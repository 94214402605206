import { useEffect,useContext} from "react"
import { Link } from "react-router-dom"
import { UserInfoContext } from "./UserInfoprovider";
import { LoadingInfoContext } from "./LoadingInfoprovider";
export default function Header(){

    const {userInfo,setUserInfo}= useContext(UserInfoContext);
    const {setLoadingInfo} = useContext(LoadingInfoContext);
    useEffect(()=>{
      const fetchUserInfo = async () => {
        try {
          const response = await fetch("https://api.wenqing.world/profile", {
            method: 'GET',
            credentials: 'include',
          });
          const data = await response.json();
          
          if (data && data.loggedIn === undefined) {
            setUserInfo(data);
          } else {
            setUserInfo(undefined);
          }
        } catch (err) {
          console.error("Failed to fetch user info:", err);
          setUserInfo(undefined);
        } finally {
          setLoadingInfo(false);
        }
      };
  
      fetchUserInfo();
    },[]);

    async function logout(e){
      await fetch("https://api.wenqing.world/logout",{
        method:"GET",
        credentials:'include',
      }).catch(err=>{
        console.log("Logout failed");
      })
      setUserInfo(undefined);
    }

    return(
        <>
      <header>
        <Link to="/" className="logo">wenqing's blog</Link>
        <nav>
            {
            userInfo&&userInfo.username.length?
            (<Link to="/CreateWelkin">Create new post</Link>):
            null
            }
            {
            userInfo&&userInfo.username.length?
            <Link onClick={logout}>Logout</Link>:
            null
            }
        </nav>
      </header>
        </>
    )
}