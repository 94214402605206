import { useState, useEffect,useContext} from "react";
import Entry from "./entry";
import { UserInfoContext } from "./UserInfoprovider";
export default function Entries() {
  const [posts, setPosts] = useState([]);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [changed,setChanged] = useState(0);
  const {userInfo} = useContext(UserInfoContext);
  const draggable = userInfo&&userInfo.username.length>0?true:false;
  useEffect(()=>{
    fetch('https://api.wenqing.world/GetPosts',{
        method:"GET",
    }).then(data=>{
        if(!data.ok)
            throw new Error();
        data.json().then(res=>{
            console.log(res);
            setPosts(res);})

    }).catch(err=>{
    })
},[changed])
  function ChangeWeight(index1,index2){
    fetch(`https://api.wenqing.world/ChangeWeight/${index1}/${index2}`,{
        method:"GET",
        credentials:"include"
    }).then(data=>{
        if(!data.ok)
            throw new Error();
        data.json().then(res=>{
            console.log(res);
        }
        )
    }).catch(err=>{
    })
  }

  const handleDragStart = (index) => {
    if(draggable)
        setDraggedIndex(index);
  };
  const handleDrop = (index) => {
    if (draggedIndex === null) return;
    if(index===draggedIndex) return;
    // 复制当前的 posts 列表
    const newPosts = [...posts];
    // 从数组中移除拖拽的元素
    const [draggedPost] = newPosts.splice(draggedIndex, 1);
    // 将元素插入到目标位置
    newPosts.splice(index, 0, draggedPost);
    // 更新状态
    ChangeWeight(draggedIndex,index);
    setPosts(newPosts);
    // 重置拖拽索引
    setDraggedIndex(null);
  };

  const handleDragOver = (e) => {
    e.preventDefault(); // 允许放置
  };

  return (

    <div className="entries">
      {posts.length > 0 ? (
        posts.map((data, index) => (
          <div
            key={data._id}
            draggable={draggable}

            onDragStart={() => handleDragStart(index)}
            onDragOver={handleDragOver}

            onDrop={() => handleDrop(index)}
            className="entry-wrapper"
          >
            <Entry 
            setChanged={setChanged}
            key={data._id} data={data} />
          </div>
        ))
      ) : (
        <h2>更多内容敬请期待！</h2>
      )}
    </div>
  );
}