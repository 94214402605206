import { useState,useRef, useContext} from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { UserInfoContext } from "./UserInfoprovider";
import { LoadingInfoContext } from "./LoadingInfoprovider";
import './Create.css';
import { Navigate } from "react-router-dom";
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];
export default function Create(){
    const quillRef = useRef(null);
    const {userInfo} = useContext(UserInfoContext);
    const [selectedImages, setSelectedImages] = useState([]);
    const [summary,setSummary] = useState('');
    const [date,setDate] = useState('');
    const [title,setTitle] = useState('');
    const [content,setContent] = useState('');
    const [Loading,setLoading] = useState(false);
    const fileInputRef = useRef(null);
    const {LoadingInfo} = useContext(LoadingInfoContext);
    const MAXSIZE = 1024*1024*10;

    const handleFileInputClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };
     
    const handleImageChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedImages(files);
    };

    const handleRemoveImage = () => {
        setSelectedImages([]);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (title.length === 0) {
            alert("Please enter title!");
            return;
        }
        if (date.length === 0) {
            alert("Please enter title!");
            return;
        }
        if (summary.length === 0) {
            alert("Please enter summary!");
            return;
        }
        if (content.length === 0) {
            alert("Please enter content!");
            return;
        }
        console.log("length:",content.length);
        if(content.length>MAXSIZE){
            alert("太大了!");
            return;
        }

        const formData = new FormData();
        setLoading(true);
        formData.set('title',title);
        formData.set('date',date);
        formData.set('summary',summary);
        formData.set('content',content);
        selectedImages.forEach((image) => {
            formData.append('avator',image);
        });
        fetch('https://api.wenqing.world/CreatePost', {
            method: 'POST',
            body: formData,
            credentials:"include"
        })
        .then(response => response.json())
        .then(data => {
            alert("上传成功了姐");
            console.log('Upload success:', data);
        })
        .catch(error => {
            alert("上传失败了,可能服务器有问题 或者大概率文件太大了");
            console.error('Upload error:', error);

        }).finally(()=>{
            setLoading(false);
        })
    };
    if(LoadingInfo===true){
        return(<h2>Loading...</h2>)
    }
    if(LoadingInfo===false){
        
        if(userInfo===undefined||userInfo.username.length===0) 
            return(
            <Navigate to="/WelkinLogin"/>
            )
    }
    return (
        <form onSubmit={handleSubmit} className="image-upload-form">
            <input className="upload-haha" type="Title" placeholder="title" value={title} onChange={(e)=>setTitle(e.target.value)}></input>
            <input className="upload-haha" type="Date" placeholder="Date" value={date} onChange={e=>setDate(e.target.value)}></input>
            <textarea className="custom-textarea" placeholder="summary" value={summary} onChange={e=>setSummary(e.target.value)}></textarea>

            <input 
                type="file" 
                name="file"
                accept="image/*" 
                onChange={handleImageChange} 
                ref={fileInputRef} 
                className="image-upload-input"
            />

            <button className="upload-button"onClick={handleFileInputClick}>Upload Cover</button>
            <h3>Preview:</h3>

            <div className="image-preview-container">
                {
                selectedImages.length?
                    (<div className="image-preview">
                        <img 
                            src={URL.createObjectURL(selectedImages[0])} 
                            alt={`Preview`} 
                            className="image-preview-img"
                        />
                        <button 
                            type="button" 
                            onClick={() => handleRemoveImage()}
                            className="image-remove-button"
                        >
                            &times;
                        </button>
                    </div>
                ):null
            }

            </div>
            <ReactQuill theme="snow" style={{
                "width":"100%",
                }} 
                ref={quillRef}
                value={content}
                modules={modules}
                formats={formats}
                onChange={newValue=>{
                    setContent(newValue)
                }}
                >
            </ReactQuill>
            {<h1>contentSize:{content.length}</h1>}
            {Loading?<h1>上传中......</h1>:null}
            <button type="submit" className="upload-button">Upload</button>
        </form>
    );
}