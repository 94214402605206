import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
const Post = ()=>{
    const {id}=useParams();
    const url = "https://api.wenqing.world/";

    const [data,setData] = useState();
    const [cont,setCont] = useState();
    useEffect(()=>{
        async function fetchData() {
            try {
                // 第一个fetch请求，获取帖子数据
                const res1 = await fetch(url + "Post/" + id, {
                    method: "GET"
                });
        
                if (!res1.ok) throw new Error("Failed to fetch post data");
        
                const data = await res1.json();
                // console.log("Data:", data);
                setData(data);
        
                // 使用获取到的帖子数据进行第二个fetch请求，获取内容
                const res2 = await fetch(url + "static/blogContents/" + data.content);
        
                if (!res2.ok) throw new Error("Failed to fetch blog content");
        
                // const arrayBuffer = await res2.arrayBuffer(); // 将 response body 转换为 ArrayBuffer
                // const uint8Array = new Uint8Array(arrayBuffer); // 将 ArrayBuffer 转换为 Uint8Array
                // const content = pako.inflate(uint8Array, { to: 'string' }); // 使用 pako 解压数据
                // console.log(arrayBuffer);
                const ct = await res2.json();
                setCont(ct.content); // 将解压后的内容设置到状态
            } catch (err) {
                console.error("Error:", err);
            }
        }
        
        // 调用函数来执行fetch
        fetchData();
    },[]);
    return (
        <div className="Post" dangerouslySetInnerHTML={{ __html: cont}} />
    )
}
export default Post;