import { useContext,useState} from "react";
import { Link } from "react-router-dom";
import { UserInfoContext } from "./UserInfoprovider";

const Entry = ({data,setChanged})=>{

    const {userInfo} = useContext(UserInfoContext);
    const styleType = data.coverImagePath?"singleEntry1":"singleEntry2";
    const url = "https://api.wenqing.world/";
    const imagePath = url + "static/"+data.coverImagePath;
    const dt = data.FakeDate.split('T')[0];

    const [showConfirm, setShowConfirm] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };
  
    const handleDeleteClick = () => {
      setShowConfirm(true);
    };
    const handleEditClick = ()=>{
      alert("尚未完成");
    }

  const handleConfirmDelete = async () => {
    // Perform the delete action here
    const res = await fetch(url+"DeletePost/"+data._id,{
      method:"GET",
      credentials:"include"
    })
    if(res.ok){
      alert("删除成功了");
      setChanged(prev=>prev+1);
    }
    else alert("失败");
    setShowConfirm(false); // Hide the confirmation popup after deletion
  };

  const handleCancelDelete = () => {
    setShowConfirm(false); // Hide the confirmation popup
  };
    return(
      <>
        <div className = {styleType}>
        {data.coverImagePath&&(

        <Link className= "PostLink" to={`post/${data._id}`}>
          <div className="image">
          <img src={imagePath} alt = "cover"></img>
        </div>
        </Link>)}

        <div className="text">
        <Link className= "PostLink" to={`post/${data._id}`}>
          <h2>{data.title}</h2>
          </Link>
          <div className="info">
            <time>{dt}</time>
          </div>
          <p className="summary">{data.summary}</p>
        </div>
        {userInfo&&userInfo.username.length>0?<div className="delete-btn" onClick={toggleDropdown} >
        {isOpen ? '▲' : '▼'}
          </div>:null}
              {/* Confirmation Popup */}
        {isOpen && (
        <ul className="dropdown-menu">
          <li onClick={handleEditClick}>Edit</li>
          <li onClick={handleDeleteClick}>Delete</li>
        </ul>
      )}
      </div>

      {showConfirm && (
        <div className="confirm-popup">
          <p>Are you sure you want to delete this post?</p>
          <button onClick={handleConfirmDelete}>Yes</button>
          <button onClick={handleCancelDelete}>No</button>
        </div>
      )}
    </>)
}
export default Entry;