import { createContext,useState} from "react";

export const LoadingInfoContext = createContext({});

export function LoadingInfoprovider({ children }){
    const [LoadingInfo, setLoadingInfo] = useState(true);
    return(
        <LoadingInfoContext.Provider value={{LoadingInfo,setLoadingInfo}}>
            {children}
        </LoadingInfoContext.Provider>
    )
}